import React, { useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip
} from "@mui/material";
import { getGridDateOperators, getGridStringOperators, GridColDef } from "@mui/x-data-grid-pro";
import { useQueryEvtStoreAggregate, useQueryEvtStoreFormOptions } from "../../api/EvtStore";
import {
  BooleanParam,
  NumberParam,
  useQueryParam,
  useQueryParams,
  withDefault
} from "use-query-params";
import { StringParam } from "serialize-query-params";
import { debounce } from "lodash";
import { collapseAllNested, defaultStyles, JsonView } from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import Loader from "../../components/Loader";
import { format, formatDistanceToNow } from "date-fns";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import { useSnackbar } from "notistack";
import { getGridOperatorsOnlyBy } from "../../utils/getGridStringOperatorsOnlyBy.ts";
import { DataGridServerIndexBasedPaginated } from "../../components/data-grid-v2/DataGridServerIndexBasedPaginated.tsx";
import { getGridSingleSelectMoreOperators } from "../../components/data-grid-v2/getGridSingleSelectMoreOperators.ts";
import { EventJSONDialog } from "./EventJSONDialog.tsx";
import useSetPageTitle from "../../hooks/useSetPageTitle.ts";
import { DataGridToolbar } from "./DataGridToolbar.tsx";

const EventStoreList = () => {
  const { enqueueSnackbar } = useSnackbar();
  useEvent(useChannel("events"), "pending", (evt: any) => {
    enqueueSnackbar(evt.event?.type, {
      anchorOrigin: {
        vertical: "top",
        horizontal: "right"
      }
    });
  });

  useSetPageTitle("Event Store");

  const [, setOpenAggregateSearch] = useQueryParam(
    "open_agg_search",
    withDefault(BooleanParam, false),
    { removeDefaultsFromUrl: true }
  );
  const [, setSearch] = useQueryParams(
    {
      stream: withDefault(StringParam, undefined),
      uuid: withDefault(StringParam, undefined),
      version: withDefault(NumberParam, undefined)
    },
    { removeDefaultsFromUrl: true }
  );
  const [, setOpenEventPosition] = useQueryParam(
    "open_event_position",
    withDefault(NumberParam, undefined),
    { removeDefaultsFromUrl: true }
  );

  const { data: formOptions } = useQueryEvtStoreFormOptions();

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "position",
        flex: 1,
        type: "number"
      },
      { field: "version", flex: 1, type: "number" },
      {
        field: "stream",
        sortable: false,
        flex: 1,
        renderCell: ({ value }) => value, // removing this will not display the cell value
        valueGetter: (value, row) => row.stream,
        type: "singleSelect",
        valueOptions: formOptions?.streams,
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "aggregate_id",
        sortable: false,
        flex: 1,
        filterOperators: getGridOperatorsOnlyBy(getGridStringOperators(), ["equals"])
      },
      {
        field: "timestamp",
        flex: 1,
        valueGetter: (value, row) => {
          return `${formatDistanceToNow(new Date(row.timestamp), {
            addSuffix: true
          })} - ${format(new Date(row.timestamp), "dd/MM/yyyy pp")}`;
        },
        sortable: false,
        filterOperators: getGridDateOperators()
      },
      {
        field: "event_type",
        flex: 1,
        valueGetter: (value, row) => {
          return row?.event?.type;
        },
        sortable: false
      },
      {
        field: "event",
        sortable: false,
        filterable: false,
        flex: 1,
        renderCell: params => {
          const {
            row: { position }
          } = params;

          return (
            <IconButton onClick={() => setOpenEventPosition(position)}>
              <ZoomInIcon />
            </IconButton>
          );
        }
      },
      {
        field: "request_id",
        sortable: false,
        flex: 1,
        filterOperators: getGridOperatorsOnlyBy(getGridStringOperators(), ["equals"])
      },
      {
        field: "author_id",
        flex: 1,
        valueGetter: (value, row) => {
          return row?.authorUser?.email;
        },
        sortable: false,
        renderCell: ({ value }) => value, // removing this will not display the cell value
        type: "singleSelect",
        valueOptions: formOptions?.users?.map((user: any) => {
          return {
            value: user.id,
            label: user.email
          };
        }),
        filterOperators: getGridSingleSelectMoreOperators()
      },
      {
        field: "actions",
        headerName: "Actions",
        filterable: false,
        sortable: false,
        headerAlign: "right",
        align: "right",
        flex: 1,
        renderCell: ({ row }) => {
          const { uuid, stream, aggregate_id, version } = row;

          return [
            <Tooltip title="Point-in-time" key={`point-in-time-${uuid}`}>
              <IconButton
                onClick={() => {
                  setSearch({ stream, uuid: aggregate_id, version });
                  setOpenAggregateSearch(true);
                }}
              >
                <ZoomInIcon />
              </IconButton>
            </Tooltip>
          ];
        }
      }
    ],
    [
      formOptions?.streams,
      formOptions?.users,
      setOpenAggregateSearch,
      setOpenEventPosition,
      setSearch
    ]
  );

  const getRowId = useCallback(({ position }: any): number => position, []);

  return (
    <>
      <Card sx={{ height: "81vh" }}>
        <DataGridServerIndexBasedPaginated
          apiEndpoint="evtstore/events"
          columns={columns}
          getRowId={getRowId}
          checkboxSelection={false}
          initialState={{
            sorting: {
              sortModel: [{ field: "position", sort: "desc" }]
            }
          }}
          slots={{
            toolbar: DataGridToolbar
          }}
        />
      </Card>

      <AggregateSearchDialog />
      <EventJSONDialog />
    </>
  );
};

export default EventStoreList;

const AggregateSearchDialog = () => {
  const [openAggregateSearch, setOpenAggregateSearch] = useQueryParam(
    "open_agg_search",
    withDefault(BooleanParam, false),
    { removeDefaultsFromUrl: true }
  );
  const [search, setSearch] = useQueryParams(
    {
      stream: withDefault(StringParam, undefined),
      uuid: withDefault(StringParam, undefined),
      version: withDefault(NumberParam, undefined)
    },
    { removeDefaultsFromUrl: true }
  );

  const { data: result, isFetching: isFetchingAggregate } = useQueryEvtStoreAggregate(
    search.stream,
    search.uuid,
    search.version
  );

  useEffect(() => {
    setSearch(current => ({
      ...current,
      version: search?.version ?? result?.version
    }));
  }, [result?.version, search?.version]);

  const handleClose = () => {
    setOpenAggregateSearch(false);
    setSearch({
      stream: undefined,
      uuid: undefined,
      version: undefined
    });
  };

  return (
    <Dialog open={openAggregateSearch} onClose={handleClose}>
      <DialogTitle>Aggregate</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Stream"
          type="text"
          fullWidth
          variant="standard"
          defaultValue={search.stream}
          onChange={debounce(
            e =>
              setSearch(current => ({
                ...current,
                stream: e.target.value
              })),
            500
          )}
        />

        <TextField
          autoFocus
          margin="dense"
          label="UUID"
          type="text"
          fullWidth
          variant="standard"
          defaultValue={search.uuid}
          onChange={debounce(
            e =>
              setSearch(current => ({
                ...current,
                uuid: e.target.value
              })),
            500
          )}
        />

        <TextField
          autoFocus
          margin="dense"
          label="Version"
          type="number"
          fullWidth
          variant="standard"
          value={search.version ?? ""}
          onChange={debounce(
            e =>
              setSearch(current => ({
                ...current,
                version: e.target.value
              })),
            500
          )}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ButtonGroup disableElevation variant="outlined" size="small">
                  <Button
                    disabled={Number(search.version ?? 0) <= 1}
                    onClick={() =>
                      setSearch(current => ({
                        ...current,
                        version: Number(current.version ?? 0) - 1
                      }))
                    }
                  >
                    -
                  </Button>
                  <Button
                    onClick={() =>
                      setSearch(current => ({
                        ...current,
                        version: Number(current.version ?? 0) + 1
                      }))
                    }
                  >
                    +
                  </Button>
                </ButtonGroup>
              </InputAdornment>
            )
          }}
        />

        <Box marginTop={3}>
          {isFetchingAggregate ? (
            <Loader />
          ) : result ? (
            <JsonView data={result} shouldExpandNode={collapseAllNested} style={defaultStyles} />
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
