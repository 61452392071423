import type { PusherProviderProps } from "@harelpls/use-pusher";

export const GoogleMapsAPIKey =
  import.meta.env.GOOGLE_MAPS_API_KEY ?? "AIzaSyBPcbNYlHtCGTUgj7Npl4zoLD247Z7SCRY";

export const apiBaseURL = import.meta.env.API_URL ?? "http://localhost:3011";

export const pportalOrdersBaseURL =
  import.meta.env.PPORTAL_ORDERS_API_URL ?? "http://localhost:3021";

export const healthCheckerIntervalMillis =
  Number(import.meta.env.HEALTH_CHECKER_INTERVAL_MILLIS || 0) || false;

export const updateCheckerIntervalMillis =
  Number(import.meta.env.UPDATE_CHECKER_INTERVAL_MILLIS || 0) || false;

export const uptimeStatusURL = import.meta.env.UPTIME_STATUS_URL;

export const pusherJSOptions: Omit<PusherProviderProps, "cluster"> = {
  clientKey: import.meta.env.PUSHER_WS_APP_KEY,
  wsHost: import.meta.env.PUSHER_WS_HOST,
  wsPort: import.meta.env.PUSHER_WS_PORT,
  wssPort: import.meta.env.PUSHER_WS_PORT,
  wsPath: import.meta.env.PUSHER_WS_PATH,
  forceTLS: import.meta.env.PROD
};

export const muiLicenseKey = import.meta.env.MUI_LICENSE_KEY ?? "";

export const openobserveRumInit = {
  clientToken: import.meta.env.OPENOBSERVE_RUM_CLIENT_TOKEN,
  site: import.meta.env.OPENOBSERVE_RUM_SITE
};
