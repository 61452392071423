import React from "react";
import { Step, StepLabel, Stepper } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { OrderStatusBit, OrderStatusV2 } from "aerp-types/types/model-interfaces.mjs";

interface OrderProgressProps {
  status?: string;
}

export const OrderProgress = ({ status }: OrderProgressProps) => {
  const defaultStatus = OrderStatusBit.EMPTY | OrderStatusBit.CHECKOUT | OrderStatusBit.OPEN;
  const statusBitMap: { [p: string]: number } = {
    [OrderStatusV2.OPEN]: defaultStatus,
    [OrderStatusV2.PROCESSING]: defaultStatus | OrderStatusBit.PROCESSING,
    [OrderStatusV2.INVOICED]: defaultStatus | OrderStatusBit.PROCESSING | OrderStatusBit.INVOICED,
    [OrderStatusV2.COMPLETE]: defaultStatus | OrderStatusBit.PROCESSING | OrderStatusBit.COMPLETE
  };

  const orderStatus = statusBitMap[status ?? ""] ?? null;

  return (
    <Stepper connector={<ChevronRightIcon />} sx={{ justifyContent: "center" }}>
      <Step completed={OrderStatusBit.OPEN === (orderStatus & OrderStatusBit.OPEN)}>
        <StepLabel>Open</StepLabel>
      </Step>
      <Step completed={OrderStatusBit.PROCESSING === (orderStatus & OrderStatusBit.PROCESSING)}>
        <StepLabel>Processing</StepLabel>
      </Step>
      <Step completed={OrderStatusBit.INVOICED === (orderStatus & OrderStatusBit.INVOICED)}>
        <StepLabel>Invoiced</StepLabel>
      </Step>
      <Step last completed={OrderStatusBit.COMPLETE === (orderStatus & OrderStatusBit.COMPLETE)}>
        <StepLabel>Complete</StepLabel>
      </Step>
    </Stepper>
  );
};
