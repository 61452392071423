import React from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { useParams } from "react-router-dom";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";
import { attachmentsDialog } from "../components/AttachmentsDialog.tsx";
import { useQueryOneOrderPublic } from "../../../api/Order.ts";

export const OptionsButton = () => {
  const { uuid } = useParams<string>();
  const { data: order } = useQueryOneOrderPublic(uuid);

  const [, setOpenPrintableModal] = useQueryParam(
    "open_printable_model",
    withDefault(BooleanParam, false),
    { removeDefaultsFromUrl: true }
  );

  const popupState = usePopupState({
    variant: "popover",
    popupId: `order-${uuid}`
  });

  return (
    <>
      <Button
        variant="outlined"
        disableElevation
        endIcon={<KeyboardArrowDownIcon />}
        {...bindTrigger(popupState)}
      >
        Options
      </Button>
      <Menu {...bindMenu(popupState)}>
        <MenuItem
          onClick={async () => {
            popupState.close();
            await attachmentsDialog({
              title: "Attachments",
              is_internal: false,
              is_public: true,
              order_id: order?.id,
              order_uuid: order?.uuid
            });
          }}
        >
          Attachments
        </MenuItem>
        <MenuItem
          onClick={async () => {
            popupState.close();
            setOpenPrintableModal(true);
          }}
          divider
        >
          Print
        </MenuItem>
      </Menu>
    </>
  );
};
