import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useMutationAbstract,
  useMutationDeleteModel,
  useQueryFormOptionsModel
} from "../hooks/useMutationFormAbstract";
import axios from "../utils/axios";
import { createErrorInfo } from "../utils/createErrorInfo.ts";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import { omit } from "lodash";
import { atom, useAtomValue } from "jotai";
import { addWeeks, endOfDay, format, startOfDay, subDays } from "date-fns";

export const QUERY_KEY = "order-fitting";

const start = subDays(new Date(), 3);
export const dateRangeFilterAtom = atom({
  start: startOfDay(start),
  end: endOfDay(addWeeks(start, 2))
});

export const queryTypeFilterAtom = atom<"pending" | "booked" | "hold" | "runs">("pending");
export const globalFilterAtom = atom<string | undefined>(undefined);
export const customerGroupFilterAtom = atom<string | undefined>(undefined);
export const fitterUsersFilterAtom = atom<string[] | undefined>(undefined);
export const jobTypesFilterAtom = atom<string[] | undefined>(undefined);
export const jobStatusesFilterAtom = atom<string[] | undefined>(undefined);
export const withTAsFilterAtom = atom<boolean | undefined>(undefined);
export const isPowderCoatFilterAtom = atom<boolean | undefined>(undefined);
export const departmentsFilterAtom = atom<string[] | undefined>(undefined);

export const paramsSummaryAtom = atom(get => {
  const dateRangeFilter = get(dateRangeFilterAtom);

  return {
    job_booked_at_between: {
      start: format(dateRangeFilter.start, "yyyy-MM-dd"),
      end: format(dateRangeFilter.end, "yyyy-MM-dd")
    },
    customer_group_uuid: get(customerGroupFilterAtom),
    query_type: get(queryTypeFilterAtom)
  };
});

export const paramsAtom = atom(get => {
  const globalFilter = get(globalFilterAtom);

  if (globalFilter) {
    return {
      customer_group_uuid: get(customerGroupFilterAtom),
      query_type: get(queryTypeFilterAtom),
      search_order_reference_number: globalFilter
    };
  }

  const dateRangeFilter = get(dateRangeFilterAtom);
  return {
    job_booked_at_between: {
      start: format(dateRangeFilter.start, "yyyy-MM-dd"),
      end: format(dateRangeFilter.end, "yyyy-MM-dd")
    },
    customer_group_uuid: get(customerGroupFilterAtom),
    fitter_user_uuids: get(fitterUsersFilterAtom),
    job_types: get(jobTypesFilterAtom),
    query_type: get(queryTypeFilterAtom),
    job_status: get(jobStatusesFilterAtom),
    with_ta: get(withTAsFilterAtom),
    is_powdercoat: get(isPowderCoatFilterAtom),
    departments: get(departmentsFilterAtom)
  };
});

export const useQueryOrderFittingBooked = () => {
  const queryClient = useQueryClient();

  useEvent(useChannel("OrderUpdated"), "handled", () =>
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY, "list"], exact: false })
  );

  useEvent(useChannel("OrderFittingCreated"), "handled", () =>
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY, "list"], exact: false })
  );

  useEvent(useChannel("OrderFittingDeleted"), "handled", (evt: any) =>
    queryClient.setQueriesData({ queryKey: [QUERY_KEY, "list"], exact: false }, (old: any) =>
      old?.filter((row: any) => row.uuid !== evt.aggregate_id)
    )
  );

  useEvent(useChannel("OrderFittingUpdated"), "handled", (evt: any) => {
    queryClient.setQueriesData({ queryKey: [QUERY_KEY, "list"], exact: false }, (old: any) =>
      old?.map((row: any) => {
        if (row.uuid !== evt.aggregate_id) {
          return row;
        }

        return {
          ...row,
          ...omit(evt.event, "type"),
          version: evt.version
        };
      })
    );
  });

  const paramsFilter = useAtomValue(paramsAtom);

  return useQuery({
    queryKey: [QUERY_KEY, "list", paramsFilter],
    queryFn: ({ queryKey: [, , filters], signal }) =>
      axios
        .get(`/api/${QUERY_KEY}/booked`, {
          signal,
          params: { filters }
        })
        .then(({ data }) => data),
    staleTime: 10000
  });
};

export const useQueryOrderFittingsSummary = () => {
  const paramsFilter = useAtomValue(paramsSummaryAtom);

  return useQuery({
    queryKey: [QUERY_KEY, "summary", paramsFilter],
    queryFn: ({ queryKey: [, , filters], signal }) =>
      axios
        .get(`/api/${QUERY_KEY}/summary`, {
          signal,
          params: { filters }
        })
        .then(({ data }) => data),
    staleTime: 10000
  });
};

export const useQueryOrderFittingFormOptions = () =>
  useQueryFormOptionsModel<{
    job_types: string[];
    job_booked_time_slots: string[];
    install_confirmation_statuses: string[];
    rebook_reasons: string[];
    job_statuses: string[];
    fitter_users: any[];
    rectification_departments: any[];
    error_reasons: any[];
    product_lines: any[];
  }>(QUERY_KEY);

export const useMutationSaveOrderFitting = () => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutationAbstract<{
    uuid: string;
    order_uuid: string;
    fitter_user_uuid?: string | null;
    rectification_department_uuid?: string | null;
    error_reason_uuid?: string | null;
    error_detail?: string | null;
    job_booked_at?: Date | null;
    job_type?: string | null;
    job_booked_time_slot?: string | null;
    install_time_allowed_in_hrs?: number | null;
    install_confirmation_status?: string | null;
    rebook_reason?: string | null;
    job_status?: string | null;
    installation_notes?: string | null;
    is_on_hold_at?: Date | null;
    ta_count?: number | null;
    powdercoat_sent_at?: Date | null;
    powdercoat_eta_at?: Date | null;
    powdercoat_arrive_at?: Date | null;
    powdercoat_notes?: string | null;
    factory_completed_at?: Date | null;
    check_measure_submitted_at?: Date | null;
    cutting_sheet_processed_at?: Date | null;
    job_booked_sequence?: number | null;
    notes_reference?: string | null;
    to_factory_at?: Date | null;
    packs?: string | null;
    is_powdercoat?: boolean | null;
  }>({
    mutationKey: [QUERY_KEY, "save"],
    mutationFn: input => axios.post(`/api/${QUERY_KEY}`, input),
    onError: error => {
      const { errorCode, errorMessage } = createErrorInfo(error as AxiosError);

      enqueueSnackbar(errorMessage, {
        variant: "error",
        preventDuplicate: true,
        key: errorCode
      });
    }
  });
};

export const useMutationDeleteOrderFitting = () => useMutationDeleteModel(QUERY_KEY);
