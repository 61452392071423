import React, { useEffect, useMemo, useState } from "react";
import { generatePath, Link as RouterLink } from "react-router-dom";
import {
  queryTypeFilterAtom,
  useQueryOrderFittingBooked,
  useQueryOrderFittingFormOptions
} from "../../api/OrderFitting.ts";
import {
  ColumnDef,
  ColumnOrderState,
  ExpandedState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  GroupingState,
  Row,
  SortingFn,
  useReactTable,
  VisibilityState
} from "@tanstack/react-table";
import {
  Box,
  Chip,
  IconButton,
  Link,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableHead as MuiTableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from "@mui/material";
import { format, parseISO } from "date-fns";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { toDecimalFromFloat } from "aldrin-erp-main-shared-functions";
import { FitterUserInput } from "./components/FitterUserInput.tsx";
import { JobTypeCell } from "./components/JobTypeCell.tsx";
import { InstallTimeAllowedInHrsInput } from "./components/InstallTimeAllowedInHrsInput.tsx";
import { SelectActionsCell } from "./components/SelectActionsCell.tsx";
import { OnHoldInputCell } from "./components/OnHoldInputCell.tsx";
import { InstallationNotesInput } from "./components/InstallationNotesInput.tsx";
import { InstallConfirmationStatusInput } from "./components/InstallConfirmationStatusInput.tsx";
import { JobStatusInput } from "./components/JobStatusInput.tsx";
import { RebookReasonInput } from "./components/RebookReasonInput.tsx";
import { ErrorReasonDetailInput } from "./components/ErrorReasonDetailInput.tsx";
import { TaCountInput } from "./components/TaCountInput.tsx";
import { FactoryCompleteInput } from "./components/FactoryCompleteInput.tsx";
import { PowdercoatSentAtInput } from "./components/PowdercoatSentAtInput.tsx";
import { PowdercoatEtaAtInput } from "./components/PowdercoatEtaAtInput.tsx";
import { PowdercoatArriveAtInput } from "./components/PowdercoatArriveAtInput.tsx";
import { PowderCoatNotesInput } from "./components/PowderCoatNotesInput.tsx";
import { JobBookedDateCell } from "./components/JobBookedDateCell.tsx";
import { JobBookedTimeSlotInput } from "./components/JobBookedTimeSlotInput.tsx";
import { JobBookedSequenceInput } from "./components/JobBookedSequenceInput.tsx";
import { ErrorReasonInput } from "./components/ErrorReasonInput.tsx";
import { RectificationDepartmentInput } from "./components/RectificationDepartmentInput.tsx";
import "./BookedList.scss";
import { NotesReferenceInput } from "./components/NotesReferenceInput.tsx";
import { useFeature } from "use-feature";
import { getOrderFittingBackgroundColor } from "../../utils/getOrderFittingBackgroundColor.ts";
import colorAlpha from "color-alpha";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import styled from "@emotion/styled";
import async from "../../components/Async.tsx";
import { CheckMeasureSubmittedAtInput } from "./components/CheckMeasureSubmittedAtInput.tsx";
import { CuttingSheetProcessedAtInput } from "./components/CuttingSheetProcessedAtInput.tsx";
import { EditableCell } from "./components/EditableCell.tsx";
import { atom, useAtom, useAtomValue } from "jotai";
import { useVirtualizer } from "@tanstack/react-virtual";
import { ColumnHeaderCell } from "./ColumnHeaderCell.tsx";
import { chain, groupBy, mapValues } from "lodash";
import { Filter } from "./Filter.tsx";
import { TableState } from "./TableState.tsx";
import { Export } from "./Export.tsx";
import { v4 } from "uuid";
import { ToFactoryInputCell } from "./components/ToFactoryInputCell.tsx";
import { PacksInput } from "./components/PacksInput.tsx";
import { IsPowdercoatInputCell } from "./components/IsPowdercoatInputCell.tsx";
import { Summary } from "./Summary.tsx";

declare module "@tanstack/react-table" {
  //add fuzzy filter to the filterFns
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const Code = async(() => import("../../components/Code"));

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 1200
  }
});

// Define a custom fuzzy filter function that will apply ranking info to rows (using match-sorter utils)
const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const customBackgroundColorForGrouped = (groupingColumnId?: string) => {
  switch (groupingColumnId) {
    case "job_booked_at":
      return "gray";
    default:
      return undefined;
  }
};

const TableHead = styled(MuiTableHead)`
  position: sticky;
  top: 0;
  z-index: 100;
`;

const boxStyles = { maxWidth: { xs: "100%", lg: "81vw" } };
const tableContainerStyles = { height: "72vh", mt: 2 };

const sortDateTimeFn: SortingFn<any> = (rowA, rowB, _columnId) => {
  const dateA = rowA.original[_columnId];
  const dateB = rowB.original[_columnId];

  return Date.parse(dateA) - Date.parse(dateB);
};

const columnVisibilityRunsTab = {
  job_booked_at: true,
  fitter_user_uuid: true,
  actions: false,
  job_booked_time_slot: true,
  job_booked_sequence: false,
  "order.reference_number": true,
  job_type: true,
  product_lines: true,
  install_time_allowed_in_hrs: true,
  ta_count: true,
  customer: true,
  customer_contact: true,
  "order.notes_reference": true,
  site_address: true,
  installation_notes: true,
  install_confirmation_status: false,
  job_status: true,
  rebook_reason: false,
  "order.deposit_amount": false,
  "order.pre_delivery_amount": false,
  "order.final_balance_amount": false,
  "order.outstanding_amount": false,
  "order.sold_price": false,
  "order.is_invoiced_at": false,
  "order.quote_accepted_at": false,
  check_measure_submitted_at: true,
  cutting_sheet_processed_at: true,
  to_factory_at: true,
  factory_completed_at: true,
  rectification_department_uuid: false,
  error_reason_uuid: false,
  error_detail: false,
  powdercoat_sent_at: true,
  powdercoat_eta_at: true,
  powdercoat_arrive_at: true,
  powdercoat_notes: true,
  is_on_hold_at: false,
  packs: true,
  is_powdercoat: true
};

const columnOrderRunsTab = [
  "version",
  "factory_completed_at",
  "check_measure_submitted_at",
  "cutting_sheet_processed_at",
  "to_factory_at",
  "is_powdercoat",
  "order.reference_number",
  "fitter_user_uuid",
  "job_booked_at",
  "job_booked_time_slot",
  "job_type",
  "product_lines",
  "install_time_allowed_in_hrs",
  "ta_count",
  "customer",
  "order.notes_reference",
  "installation_notes",
  "job_status",
  "customer_contact",
  "site_address",
  "packs"
];

export const expandedStateAtom = atom<ExpandedState>(true);

const BookedList = () => {
  const { data: formOptions } = useQueryOrderFittingFormOptions();
  const { data: orderFittings } = useQueryOrderFittingBooked();

  const rowsData = useMemo(() => {
    return orderFittings?.map((row: any) => {
      return {
        ...row,
        backgroundColor: getOrderFittingBackgroundColor(row)
      };
    });
  }, [orderFittings]);

  const jobTypesGroupedByOrder = useMemo(() => {
    return chain(orderFittings)
      .groupBy("order.uuid")
      .mapValues(orders => mapValues(groupBy(orders, "job_type"), rows => rows.length))
      .value();
  }, [orderFittings]);

  const columns = useMemo<ColumnDef<any, unknown>[]>(
    () => [
      {
        accessorFn: row => row.job_booked_at && format(parseISO(row.job_booked_at), "EEE dd MMM"),
        id: "job_booked_at",
        cell: info => {
          const {
            uuid,
            job_booked_at,
            order: { uuid: order_uuid }
          } = info.row.original;

          return (
            <EditableCell info={info}>
              <JobBookedDateCell
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                job_booked_at={job_booked_at}
              />
            </EditableCell>
          );
        },
        sortingFn: sortDateTimeFn,
        size: 140,
        header: () => <span>Booked Date</span>,
        enableHiding: false,
        enableGlobalFilter: false
      },
      {
        id: "fitter_user_uuid",
        accessorFn: row =>
          formOptions?.fitter_users?.find(user => user.uuid === row.fitter_user_uuid)?.full_name ||
          undefined,
        cell: info => {
          const {
            uuid,
            fitter_user_uuid,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <FitterUserInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                fitter_user_uuid={fitter_user_uuid}
              />
            </EditableCell>
          );
        },
        size: 180,
        header: () => <span>Fitter</span>,
        enableHiding: false,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => row.version,
        id: "version",
        size: 50,
        header: () => <span>Version</span>,
        enableGlobalFilter: false
      },
      {
        id: "actions",
        cell: info => {
          return (
            <SelectActionsCell row={info.row.original} jobTypesCount={jobTypesGroupedByOrder} />
          );
        },
        header: () => <span>Actions</span>,
        enableHiding: false,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => row.job_booked_time_slot || undefined,
        id: "job_booked_time_slot",
        size: 80,
        cell: info => {
          const {
            uuid,
            job_booked_time_slot,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <JobBookedTimeSlotInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                job_booked_time_slot={job_booked_time_slot}
              />
            </EditableCell>
          );
        },
        header: () => <span>Time Slot</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => row.job_booked_sequence || undefined,
        id: "job_booked_sequence",
        size: 60,
        cell: info => {
          const {
            uuid,
            job_booked_sequence,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <JobBookedSequenceInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                job_booked_sequence={job_booked_sequence}
              />
            </EditableCell>
          );
        },
        header: () => <span>Sequence</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => row.order?.reference_number,
        id: "order.reference_number",
        cell: info => {
          const order = info.row.original?.order;

          // if (ability.cannot("Read", "order")) {
          //   return order?.reference_number;
          // }

          if (order) {
            return (
              <Link
                component={RouterLink}
                underline="none"
                to={generatePath("/order/:uuid/finalized-quote", {
                  uuid: order?.uuid
                })}
              >
                <strong>{order?.reference_number}</strong>
              </Link>
            );
          }
        },
        header: () => <span>Order #</span>,
        filterFn: "includesStringSensitive",
        enableColumnFilter: true
      },
      {
        accessorFn: row => row.job_type,
        id: "job_type",
        cell: info => {
          const {
            uuid,
            job_type,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <JobTypeCell uuid={uuid ?? info.row.id} order_uuid={order_uuid} job_type={job_type} />
            </EditableCell>
          );
        },
        size: 80,
        header: () => <span>Job Type</span>,
        enableGlobalFilter: false
      },
      {
        id: "product_lines",
        cell: info => {
          const order_line_items = info.row.original.order?.order_line_items;

          return order_line_items?.join(", ");
        },
        header: () => <span>Dept</span>,
        enableGlobalFilter: false,
        size: 150
      },
      {
        accessorFn: row => row.install_time_allowed_in_hrs,
        id: "install_time_allowed_in_hrs",
        cell: info => {
          const {
            uuid,
            install_time_allowed_in_hrs,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <InstallTimeAllowedInHrsInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                install_time_allowed_in_hrs={install_time_allowed_in_hrs}
              />
            </EditableCell>
          );
        },
        size: 80,
        header: () => <span>Time</span>,
        enableGlobalFilter: false
      },
      {
        id: "ta_count",
        accessorFn: row => row.ta_count,
        cell: info => {
          const {
            uuid,
            ta_count,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <TaCountInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                ta_count={ta_count}
              />
            </EditableCell>
          );
        },
        size: 80,
        header: () => <span># of TAs</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => row.order?.customer?.company_name || row.order?.customer?.full_name,
        id: "customer",
        cell: info => {
          const customer = info.row.original?.order?.customer;
          const value = info.getValue() as string;

          // if (ability.cannot("Read", "customers")) {
          //   return customer?.full_name;
          // }

          if (value) {
            return (
              <Link
                component={RouterLink}
                underline="none"
                to={generatePath("/customers/:uuid/details", {
                  uuid: customer?.uuid
                })}
              >
                <strong>{value}</strong>
              </Link>
            );
          }
        },
        header: () => <span>Customer</span>
      },
      {
        id: "customer_contact",
        cell: info => {
          const { customer } = info.row.original.order;
          return Object.entries({
            Phone: customer?.phone_number,
            Mobile: customer?.mobile_number
          })
            .map(([type, numberValue]) => {
              return numberValue ? `${type}: ${numberValue}` : undefined;
            })
            .filter(Boolean)
            .join(", ");
        },
        header: () => <span>Contact #</span>,
        enableGlobalFilter: false,
        size: 150
      },
      {
        accessorFn: row => row.order?.notes_reference,
        id: "order.notes_reference",
        size: 250,
        cell: info => {
          const {
            uuid,
            order: { uuid: order_uuid, notes_reference }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <NotesReferenceInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                notes_reference={notes_reference}
              />
            </EditableCell>
          );
        },
        header: () => <span>Reference</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => row.order?.site_address || row.order?.customer?.billing_address,
        id: "site_address",
        cell: info => info.getValue(),
        size: 250,
        header: () => <span>Site Address</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => row.installation_notes,
        id: "installation_notes",
        size: 250,
        cell: info => {
          const {
            uuid,
            installation_notes,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <InstallationNotesInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                installation_notes={installation_notes}
              />
            </EditableCell>
          );
        },
        header: () => <span>Installation Notes</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => row.install_confirmation_status,
        id: "install_confirmation_status",
        cell: info => {
          const {
            uuid,
            install_confirmation_status,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <InstallConfirmationStatusInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                install_confirmation_status={install_confirmation_status}
              />
            </EditableCell>
          );
        },
        header: () => <span>Confirmation Status</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => row.job_status,
        id: "job_status",
        cell: info => {
          const {
            uuid,
            job_status,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <JobStatusInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                job_status={job_status}
              />
            </EditableCell>
          );
        },
        header: () => <span>Job Status</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => row.rebook_reason,
        id: "rebook_reason",
        cell: info => {
          const {
            uuid,
            rebook_reason,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <RebookReasonInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                rebook_reason={rebook_reason}
              />
            </EditableCell>
          );
        },
        header: () => <span>Rebook Reason</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => toDecimalFromFloat(row?.order?.deposit_amount ?? 0),
        id: "order.deposit_amount",
        header: () => <span>Deposit</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => toDecimalFromFloat(row?.order?.pre_delivery_amount ?? 0),
        id: "order.pre_delivery_amount",
        header: () => <span>Pre-Delivery</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => toDecimalFromFloat(row?.order?.final_balance_amount ?? 0),
        id: "order.final_balance_amount",
        header: () => <span>Final Balance</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => toDecimalFromFloat(row?.order?.outstanding_amount ?? 0),
        id: "order.outstanding_amount",
        header: () => <span>Outstanding Balance</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => toDecimalFromFloat(row?.order?.sold_price ?? 0),
        id: "order.sold_price",
        header: () => <span>Sold Price</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => (row.order.is_invoiced_at ? "Yes" : "No"),
        id: "order.is_invoiced_at",
        header: () => <span>Invoiced</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row =>
          row.order.quote_accepted_at &&
          format(parseISO(row.order.quote_accepted_at), "dd/MM/yyyy"),
        id: "order.quote_accepted_at",
        header: () => <span>Date Accepted</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row =>
          row.check_measure_submitted_at &&
          format(parseISO(row.check_measure_submitted_at), "dd/MM/yyyy"),
        id: "check_measure_submitted_at",
        cell: info => {
          const {
            uuid,
            check_measure_submitted_at,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <CheckMeasureSubmittedAtInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                check_measure_submitted_at={check_measure_submitted_at}
              />
            </EditableCell>
          );
        },
        size: 100,
        header: () => <span>Cut Sheet In</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row =>
          row.cutting_sheet_processed_at &&
          format(parseISO(row.cutting_sheet_processed_at), "dd/MM/yyyy"),
        id: "cutting_sheet_processed_at",
        cell: info => {
          const {
            uuid,
            cutting_sheet_processed_at,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <CuttingSheetProcessedAtInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                cutting_sheet_processed_at={cutting_sheet_processed_at}
              />
            </EditableCell>
          );
        },
        size: 100,
        header: () => <span>Cut Sheet Out</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => (row.is_powdercoat === null ? null : row.is_powdercoat ? "Yes" : "No"),
        id: "is_powdercoat",
        cell: info => {
          const {
            uuid,
            is_powdercoat,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <IsPowdercoatInputCell
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                is_powdercoat={is_powdercoat}
              />
            </EditableCell>
          );
        },
        size: 100,
        header: () => <span>Is Powdercoat</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => row.to_factory_at && format(parseISO(row.to_factory_at), "dd/MM"),
        id: "to_factory_at",
        cell: info => {
          const {
            uuid,
            to_factory_at,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <ToFactoryInputCell
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                to_factory_at={to_factory_at}
              />
            </EditableCell>
          );
        },
        header: () => <span>T/F</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row =>
          row.factory_completed_at && format(parseISO(row.factory_completed_at), "dd/MM/yyyy"),
        id: "factory_completed_at",
        cell: info => {
          const {
            uuid,
            factory_completed_at,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <FactoryCompleteInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                factory_completed_at={factory_completed_at}
              />
            </EditableCell>
          );
        },
        size: 80,
        header: () => <span>Factory Completed</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row =>
          formOptions?.rectification_departments?.find(
            record => record.uuid === row.rectification_department_uuid
          )?.name,
        id: "rectification_department_uuid",
        cell: info => {
          const {
            uuid,
            rectification_department_uuid,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <RectificationDepartmentInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                rectification_department_uuid={rectification_department_uuid}
              />
            </EditableCell>
          );
        },
        header: () => <span>Rectification Department</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row =>
          formOptions?.error_reasons?.find(record => record.uuid === row.error_reason_uuid)?.name,
        id: "error_reason_uuid",
        cell: info => {
          const {
            uuid,
            error_reason_uuid,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <ErrorReasonInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                error_reason_uuid={error_reason_uuid}
              />
            </EditableCell>
          );
        },
        header: () => <span>Error Reason</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => row.error_detail,
        id: "error_detail",
        cell: info => {
          const {
            uuid,
            error_detail,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <ErrorReasonDetailInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                error_detail={error_detail}
              />
            </EditableCell>
          );
        },
        header: () => <span>Error Detail</span>,
        size: 200,
        enableGlobalFilter: false
      },
      {
        accessorFn: row =>
          row.powdercoat_sent_at && format(parseISO(row.powdercoat_sent_at), "dd/MM/yyyy"),
        id: "powdercoat_sent_at",
        cell: info => {
          const {
            uuid,
            powdercoat_sent_at,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <PowdercoatSentAtInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                powdercoat_sent_at={powdercoat_sent_at}
              />
            </EditableCell>
          );
        },
        size: 160,
        header: () => <span>Date PC Sent</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row =>
          row.powdercoat_eta_at && format(parseISO(row.powdercoat_eta_at), "dd/MM/yyyy"),
        id: "powdercoat_eta_at",
        cell: info => {
          const {
            uuid,
            powdercoat_eta_at,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <PowdercoatEtaAtInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                powdercoat_eta_at={powdercoat_eta_at}
              />
            </EditableCell>
          );
        },
        size: 160,
        header: () => <span>ETA</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row =>
          row.powdercoat_arrive_at && format(parseISO(row.powdercoat_arrive_at), "dd/MM/yyyy"),
        id: "powdercoat_arrive_at",
        cell: info => {
          const {
            uuid,
            powdercoat_arrive_at,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <PowdercoatArriveAtInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                powdercoat_arrive_at={powdercoat_arrive_at}
              />
            </EditableCell>
          );
        },
        size: 160,
        header: () => <span>Date PC In</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => row.powdercoat_notes,
        id: "powdercoat_notes",
        size: 200,
        cell: info => {
          const {
            uuid,
            powdercoat_notes,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <PowderCoatNotesInput
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                powdercoat_notes={powdercoat_notes}
              />
            </EditableCell>
          );
        },
        header: () => <span>Detail</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => (row.is_on_hold_at ? "Yes" : "No"),
        id: "is_on_hold_at",
        cell: info => {
          const {
            uuid,
            is_on_hold_at,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <OnHoldInputCell
                uuid={uuid ?? info.row.id}
                order_uuid={order_uuid}
                is_on_hold_at={is_on_hold_at}
              />
            </EditableCell>
          );
        },
        header: () => <span>On Hold</span>,
        enableGlobalFilter: false
      },
      {
        accessorFn: row => row.packs,
        id: "packs",
        size: 250,
        cell: info => {
          const {
            uuid,
            packs,
            order: { uuid: order_uuid }
          } = info.row.original;
          return (
            <EditableCell info={info}>
              <PacksInput uuid={uuid ?? info.row.id} order_uuid={order_uuid} packs={packs} />
            </EditableCell>
          );
        },
        header: () => <span>Packs</span>,
        enableGlobalFilter: false
      }
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobTypesGroupedByOrder]
  );

  const debugOrder = useFeature("FEATURE_DEBUG_ORDER");

  const [expanded, setExpanded] = useAtom(expandedStateAtom);
  const [grouping, setGrouping] = useState<GroupingState>(["job_booked_at", "fitter_user_uuid"]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    version: debugOrder
  });
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);

  const queryType = useAtomValue(queryTypeFilterAtom);

  useEffect(() => {
    switch (queryType) {
      case "runs":
        setGrouping([]);
        setColumnVisibility({ ...columnVisibilityRunsTab, version: debugOrder });
        setColumnOrder(columnOrderRunsTab);
        break;
      case "pending":
        setGrouping([]);
        setColumnVisibility({
          actions: false,
          version: debugOrder,
          product_lines: false,
          packs: false,
          to_factory_at: false,
          is_powdercoat: false
        });
        break;
      case "hold":
        setGrouping(["job_booked_at", "fitter_user_uuid"]);
        setColumnVisibility({
          "order.deposit_amount": false,
          "order.pre_delivery_amount": false,
          "order.final_balance_amount": false,
          packs: false,
          to_factory_at: false,
          is_powdercoat: false
        });
        setColumnOrder([]);
        break;
      default:
        setGrouping(["job_booked_at", "fitter_user_uuid"]);
        setColumnVisibility({
          version: debugOrder,
          packs: false,
          to_factory_at: false,
          is_powdercoat: false
        });
        setColumnOrder([]);
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryType, debugOrder]);

  const table = useReactTable({
    data: rowsData,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    globalFilterFn: "fuzzy",
    state: {
      expanded,
      grouping,
      columnVisibility,
      columnOrder
    },
    onGroupingChange: setGrouping,
    onExpandedChange: setExpanded,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    initialState: {
      sorting: [
        {
          id: "job_booked_at",
          desc: false
        },
        {
          id: "fitter_user_uuid",
          desc: false
        },
        {
          id: "job_booked_time_slot",
          desc: false
        },
        {
          id: "job_booked_sequence",
          desc: false
        }
      ]
    },
    autoResetPageIndex: false,
    autoResetExpanded: false,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // getFacetedUniqueValues: getFacetedUniqueValues(),
    getRowId: row => row.uuid ?? v4(),
    defaultColumn: {
      size: 120,
      minSize: 100,
      maxSize: 500,
      footer: props => props.column.id,
      sortUndefined: "last",
      enableColumnFilter: false
    },
    manualPagination: true
  });

  const { rows } = table.getRowModel();

  const sortedRowIDs = useMemo(() => {
    return table
      .getSortedRowModel()
      .flatRows?.filter(row => !row.getIsGrouped())
      ?.map(row => row.id);
  }, [table.getSortedRowModel()]);

  const visibleColumns = table.getVisibleLeafColumns();

  const tableContainerRef = React.useRef<HTMLDivElement>(null);

  const columnVirtualizer = useVirtualizer({
    count: visibleColumns.length,
    estimateSize: index => visibleColumns[index].getSize() + 50, //estimate width of each column for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    horizontal: true,
    overscan: 5 //how many columns to render on each side off screen each way (adjust this for performance)
  });

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== "undefined" && navigator.userAgent.indexOf("Firefox") === -1
        ? element => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5
  });

  const virtualColumns = columnVirtualizer.getVirtualItems();
  const virtualRows = rowVirtualizer.getVirtualItems();

  let virtualPaddingLeft: number | undefined;
  let virtualPaddingRight: number | undefined;

  if (columnVirtualizer && virtualColumns?.length) {
    virtualPaddingLeft = virtualColumns[0]?.start ?? 0;
    virtualPaddingRight =
      columnVirtualizer.getTotalSize() - (virtualColumns[virtualColumns.length - 1]?.end ?? 0);
  }

  return (
    <Box sx={boxStyles}>
      <Stack
        direction="row"
        alignItems="stretch"
        justifyContent="space-between"
        flexWrap="wrap"
        gap={3}
        my={3}
      >
        <Stack
          direction="row"
          alignItems="stretch"
          justifyContent="space-between"
          flexWrap="wrap"
          gap={3}
        >
          <Filter />

          {["hold", "runs"].includes(queryType) && <Export sorting={sortedRowIDs} />}

          <Summary />
        </Stack>

        <TableState totalCount={orderFittings?.length ?? 0} />
      </Stack>

      <TableContainer component={Paper} sx={tableContainerStyles} ref={tableContainerRef}>
        <table style={{ display: "grid" }}>
          <TableHead
            style={{
              display: "grid",
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "white",
              height: 77
              // alignItems: "baseline"
            }}
          >
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id} style={{ display: "flex", width: "100%" }}>
                {virtualPaddingLeft ? (
                  //fake empty column to the left for virtualization scroll padding
                  <TableCell style={{ display: "flex", width: virtualPaddingLeft }} />
                ) : null}
                <TableCell style={{ display: "flex", width: debugOrder ? 150 : 20 }} />
                {virtualColumns.map(vc => {
                  const header = headerGroup.headers[vc.index];
                  return <ColumnHeaderCell key={header.id} header={header} />;
                })}
                {virtualPaddingRight ? (
                  //fake empty column to the right for virtualization scroll padding
                  <TableCell style={{ display: "flex", width: virtualPaddingRight }} />
                ) : null}
              </TableRow>
            ))}
          </TableHead>
          <TableBody
            style={{
              display: "grid",
              height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
              position: "relative" //needed for absolute positioning of rows
            }}
          >
            {virtualRows.map(virtualRow => {
              const row = rows[virtualRow.index] as Row<any>;
              const visibleCells = row.getVisibleCells();

              const groupedBgColor = customBackgroundColorForGrouped(row.groupingColumnId);
              const backgroundColor = row.getIsGrouped()
                ? groupedBgColor
                : row.original.backgroundColor;

              return (
                <TableRow
                  data-index={virtualRow.index} //needed for dynamic row height measurement
                  ref={node => rowVirtualizer.measureElement(node)} //measure dynamic row height
                  key={row.id}
                  style={{
                    display: "flex",
                    position: "absolute",
                    transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                    width: "100%",
                    backgroundColor: backgroundColor ? colorAlpha(backgroundColor, 0.1) : undefined,
                    minHeight: 67
                  }}
                >
                  {virtualPaddingLeft ? (
                    //fake empty column to the left for virtualization scroll padding
                    <TableCell style={{ display: "flex", width: virtualPaddingLeft }} />
                  ) : null}

                  <TableCell
                    style={{ display: "flex", width: debugOrder ? 150 : 20, backgroundColor }}
                  >
                    {!row.getIsGrouped() && debugOrder && (
                      <CustomWidthTooltip title={<Code>{JSON.stringify(row, null, "\t")}</Code>}>
                        <Chip
                          label={row.original.uuid}
                          size="small"
                          sx={{ backgroundColor: "white" }}
                        />
                      </CustomWidthTooltip>
                    )}
                  </TableCell>

                  {virtualColumns.map(vc => {
                    const cell = visibleCells[vc.index];
                    return (
                      <TableCell
                        key={cell.id}
                        style={{
                          display: "flex",
                          width: cell.column.getSize(),
                          flexDirection: "column",
                          justifyContent: "center",
                          textWrap: "wrap"
                        }}
                      >
                        {cell.getIsGrouped() ? (
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            useFlexGap
                            sx={{ minWidth: 200 }}
                          >
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={row.getToggleExpandedHandler()}
                            >
                              {row.getIsExpanded() ? (
                                <KeyboardArrowDownIcon />
                              ) : (
                                <KeyboardArrowRightIcon />
                              )}
                            </IconButton>
                            <Typography>
                              <strong>{(cell.getValue() as string) || "None"}</strong>
                            </Typography>
                            {cell.column.id === "fitter_user_uuid" ? (
                              <>
                                <Chip
                                  label={`${row.subRows
                                    .filter(row => row.original.job_status !== "to_be_rebooked")
                                    .reduce((sum, row) => {
                                      return (sum += row.original.install_time_allowed_in_hrs);
                                    }, 0)
                                    .toFixed(2)} hrs`}
                                  size="small"
                                  variant="outlined"
                                />
                                {/*<Chip*/}
                                {/*  label={`$${row.subRows.reduce((sum, row) => {*/}
                                {/*    return (sum += row.original.order.sold_price);*/}
                                {/*  }, 0)}`}*/}
                                {/*  size="small"*/}
                                {/*  variant="outlined"*/}
                                {/*/>*/}
                              </>
                            ) : (
                              <Chip label={row.subRows.length} size="small" variant="outlined" />
                            )}
                          </Stack>
                        ) : cell.getIsAggregated() ? null : cell.row.getIsGrouped() && // renderer for cell // If the cell is aggregated, use the Aggregated
                          cell.getIsPlaceholder() ? null : (
                          flexRender(cell.column.columnDef.cell, cell.getContext())
                        )}

                        {/*{flexRender(cell.column.columnDef.cell, cell.getContext())}*/}
                      </TableCell>
                    );
                  })}
                  {virtualPaddingRight ? (
                    //fake empty column to the right for virtualization scroll padding
                    <TableCell style={{ display: "flex", width: virtualPaddingRight }} />
                  ) : null}
                </TableRow>
              );
            })}
          </TableBody>
        </table>
      </TableContainer>
    </Box>
  );
};

BookedList.whyDidYouRender = true;

export default BookedList;
