import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { BooleanParam, useQueryParam, withDefault } from "use-query-params";
import asyncComponent from "../../components/Async";
import { capitalize } from "lodash";

const OrderFinalizedPDFViewer = asyncComponent(
  () => import("./pdf/order_finalized/OrderFinalizedPDFViewer")
);

export const OrderFinalizedPDF = ({
  order,
  public_view
}: {
  order: any;
  public_view?: boolean;
}) => {
  const [openPrintableModal, setOpenPrintableModal] = useQueryParam(
    "open_printable_model",
    withDefault(BooleanParam, false),
    { removeDefaultsFromUrl: true }
  );

  const handleClose = () => setOpenPrintableModal(false);

  return (
    <Dialog fullScreen open={!!openPrintableModal} onClose={handleClose}>
      <DialogTitle>{`${capitalize(order?.order_type)} ${order?.reference_number}`}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <OrderFinalizedPDFViewer order={order} public_view={public_view} />
      </DialogContent>
    </Dialog>
  );
};
